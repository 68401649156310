<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <div class="hd-search">
        <table-search
          :model="searchParams"
          label-width="80px"
          :isClear="true"
          :flex="'4'"
          @search="searchFn"
          @clearSearch="clearSearchFn"
        >
          <search-item default label="">
            <el-input
              size="small"
              v-model="searchParams.channelName"
              placeholder="栏目名称"
              clearable
            />
          </search-item>
          <search-item default label="">
            <el-select
              v-model="searchParams.channelType"
              placeholder="栏目类型"
              clearable
            >
              <el-option
                v-for="item in columns"
                :key="item.dicCode"
                :label="item.dicName"
                :value="item.dicCode"
              >
              </el-option>
            </el-select>
          </search-item>
        </table-search>
      </div>
      <div class="bd-content">
        <div class="table-opt">
          <span
            style="
              margin-right: 2rem;
              font-size: 20px;
              font-weight: 800;
              padding: 2px;
            "
            >{{ this.$route.query.agencyName }}</span
          >
          <el-button
            type="primary"
            class="el-icon-circle-plus-outline"
            @click="add({})"
          >
            新 增
          </el-button>
          <el-button
            type="info"
            plain
            icon="el-icon-sort"
            @click="toggleExpandAll"
          >展开/折叠</el-button>
        </div>
        <div class="com-data-table">
          <el-table
            v-if="refreshTable"
            v-loading="loading"
            :data="menuList"
            :default-expand-all="isExpandAll"
            row-key="id"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column
              prop="channelName"
              label="栏目名称"
            ></el-table-column>
            <el-table-column prop="channelType" label="栏目类型">
            </el-table-column>
            <el-table-column
              prop="channelUrl"
              label="网页地址"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <a
                  @click="openWin(scope.row.channelUrl)"
                  style="cursor: pointer"
                  >{{ scope.row.channelUrl }}</a
                >
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              class-name="small-padding fixed-width"
              width="120"
            >
              <template slot-scope="scope">
                <div class="data-operate">
                  <span class="op-blue" @click="eidt(scope.row)"> 编辑 </span>
                  <span class="op-red" @click="del(scope.row)"> 删除 </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <add-column
      v-if="dialogVisible"
      :dialogType="dialogType"
      :dialogVisible.sync="dialogVisible"
      :menuData="menData"
      :agencyId="this.$route.query.agencyId"
      :columns="columns"
      :menuList="menuList"
      @update="searchFn"
    />
  </div>
</template>
  
  <script>
import addColumn from './addColumn.vue';
export default {
  name: 'webColumn',
  components: {
    addColumn,
  },
  data() {
    return {
      // 菜单表格树数据
      menuList: [],
      selectData: [],
      columns: [],
      //栏目类型
      loading: false,
      searchParams: {
        channelName:'',
        channelType:'',
        agencyId: this.$route.query.agencyId,
      },
      searchAble: false,
      dialogVisible: false,
      dialogType: '',
      menData: {},
      // 是否展开，默认全部折叠
      isExpandAll: true,
      // 重新渲染表格状态
      refreshTable: true,
    };
  },
  mounted() {
    this.tableRef = this.$refs.table;
    this.getList();
    this.getColumns();
  },
  methods: {
    openWin(url) {
      if (url) {
        window.open(url);
      }
    },
    /** 展开/折叠操作 */
    toggleExpandAll() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
    /** 查询菜单列表 */
    async getList() {
      this.loading = true;
      await this.$api.operationOrder
        .getGrabChannelList({
          ...this.searchParams,
        })
        .then((res) => {
          if (res.data) {
            this.menuList = res.data;
          } else {
            this.menuList = [];
          }

          this.loading = false;
        });
    },
    //加载栏目类型数组
    async getColumns() {
      let obj = {
        category: '栏目类型',
        jb: '2',
      };
      let res = await this.$api.operationOrder.getColumns(obj);
      if (res.data) {
        this.columns = res.data;
      }
    },
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },
    searchFn() {
      this.getList();
    },
    clearSearchFn() {},
    add(row) {
      this.menData = row;
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    eidt(row) {
      this.menData = row;
      this.dialogType = 'edit';
      this.dialogVisible = true;
    },
    del(params) {
      if (params === 'more' && this.selectData.length === 0) {
        this.$message({
          message: '请先勾选数据',
          type: 'warning',
        });
        return;
      }
      this.$msg.confirm('确认删除菜单？').then(() => {
        this.$api.operationOrder
          .deleteGrabChannel({
            ids:
              params === 'more'
                ? this.selectData.map((i) => i.id).join()
                : params.id,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.getList();
            }
          });
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
::v-deep .bd-content .com-data-table .el-table .el-table__header th.is-leaf {
  background: rgb(241, 241, 241) !important;
}
</style>
  