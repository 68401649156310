<template>
  <el-dialog
    :title="dialogType === 'edit' ? '编辑栏目' : '新增栏目'"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="30%"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-form-item label="上级类目" prop="parentId">
          <treeselect
            v-model="form.parentId"
            :options="menuList"
            :normalizer="normalizer"
            :show-count="true"
            :defaultExpandLevel="Infinity"
            placeholder="选择上级类目"
          />
          <!-- <el-select
            ref="selectClassify"
            v-model="form.parentName"
            placeholder=""
            style="width: 100%"
            clearable
            filterable
          > -->
          <!-- <el-option :value="form.parentId" style="height: auto"  > -->
          <!-- <tree
                ref="tree"
                v-if="showTree"
                :tree-type="'form'"
                :initFalse="initFalse"
                :params="treeParams"
                :defaultProps='defaultProps'
                :tData="treeData"
                :api-function="this.$api.operationOrder.getGrabChannelList"
                @nodeClick="handlerNodeClick"
              /> -->
          <!-- <el-tree
            :data="menuList"
            :props="defaultProps"
            default-expand-all
            @node-click="handlerNodeClick"
            ref="tree"
          ></el-tree> -->
          <!-- </el-option> -->
          <!-- </el-select> -->
        </el-form-item>
        <el-form-item label="栏目名称" prop="channelName">
          <el-input v-model="form.channelName" clearable></el-input>
        </el-form-item>
        <el-form-item label="网页地址" prop="channelUrl">
          <el-input v-model="form.channelUrl"></el-input>
        </el-form-item>
        <el-form-item label="栏目类型">
          <el-select v-model="form.channelType" clearable>
            <el-option
              v-for="item in columns"
              :key="item.dicCode"
              :label="item.dicName"
              :value="item.dicCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveFn">确 定</el-button>
    </span>
  </el-dialog>
</template>
  <script>
// import tree from '@/views/sysManage/component/tree.vue';
import { transformTree } from '@/utils/utils.js';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  name: 'addMenu',
  props: {
    dialogVisible: { type: Boolean, require },
    menuData: { type: Object, default: () => ({}) },
    dialogType: { type: String, require },
    agencyId: { type: String, require },
    columns: { type: Array, default: () => [] },
    menuList: { type: Array, default: () => [] },
  },
  components: {
    // tree,
    Treeselect,
  },
  data() {
    return {
      dialogFormVisible: this.dialogVisible,
      treeParams: {
        agencyId: this.agencyId,
      },
      form: {
        agencyId: this.agencyId,
        parentId: null,
        channelName: '',
        channelType: '',
        channelUrl: '',
      },
      defaultProps: {
        children: 'children',
        label: 'channelName',
      },
      treeData: [],
      rules: {
        channelName: [{ required: true, message: '请输入栏目名称' }],
        channelUrl: [{ required: true, message: '请输入网页地址' }],
      },
    };
  },
  mounted() {
    // //如果是编辑的话放数据
    if (this.dialogType == 'edit') {
      for (let key in this.form) {
        this.form[key] = this.menuData[key] || null;
      }
      this.form.agencyId = this.agencyId;
      this.form.id = this.menuData.id;
    }
  },
  methods: {
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.channelName,
        children: node.children && node.children.length > 0 ? node.children : 0,
      };
    },
    add() {
      this.$api.operationOrder.addGrabChannel(this.form).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    eidt() {
      this.$api.operationOrder.editGrabChannel(this.form).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    saveFn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.channelName === '') {
            this.form.parentId = '';
          }
          this.dialogType === 'add' ? this.add() : this.eidt();
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
.dialog-content {
  width: 90%;
  margin: 0 auto;
}
::v-deep .el-tree > .el-tree-node {
  display: block;
}
</style>
  